<template>
  <apex-chart
    type="bar"
    :series="series"
    :options="chartOptions"
    height="95%"
  />
</template>

<script>
import ApexChart from 'vue-apexcharts'
import { mixins } from 'vue-chartjs'

const { reactiveProp } = mixins
export default {
  components: {
    ApexChart,
  },
  mixins: [reactiveProp],
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories: [],
          labels: {
            style: {
              colors: '#777777',
            },
          },
        },
        yaxis: {
          labels: {
            style: {
              colors: '#717171',
            },
          },
        },
        fill: {
          opacity: 0.8,
        },
        legend: {
          position: 'top',
          labels: {
            colors: '#777777',
          },
        },
      },
    }
  },
  watch: {
    chartData: {
      handler(newVal) {
        if (newVal.datasets && newVal.datasets.length >= 1) {
          if (newVal.datasets.length > 1) {
            this.chartOptions.plotOptions.bar.distributed = false
          }
          this.chartOptions.xaxis.categories = newVal.labels.map(v => (v != null ? v : 'NO_VALUE'))
          for (let i = 0; i < newVal.datasets.length; i++) {
            const element = {
              name: newVal.datasets[i].label === '' ? newVal.labels[i] : newVal.datasets[i].label,
              data: newVal.datasets[i].data,
            }
            this.series.push(element)
          }
        }
      },
      immediate: true,
    },
  },
}
</script>

<style>
.apexcharts-tooltip {
  background: #f3f3f3;
  color: rgb(59, 59, 58);
}
</style>
